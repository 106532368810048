<template>
    <v-container fluid v-bind:class="[Object.values(sessions).length !== 0 ? 'fill-height' : '']" class="debugger">
        <v-container fluid v-if="Object.values(sessions).length === 0" id="splash" class="fill-height">
            <v-img :src="require('../../public/brakecode-dashboard-2-cropped.png')">
                <div class="fill-height gradient"></div>
                <v-overlay absolute opacity="0.1"><span class="text-md-subtitle-1 text-lg-h2">Add sessions from the PADS dashboard.</span></v-overlay>
            </v-img>
        </v-container>
        <v-tabs v-else v-model="tab" :vertical="!$vuetify.breakpoint.mobile" style="height: 100%" class="fill-height">
            <v-tab v-for="session in sessions" v-bind:key="`${session.cid} ${session.inspectorId}`" :href="`#${session.cid}`" @contextmenu="show">
                <v-tooltip top open-delay="100">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="text-caption text-lg-body-2 text-truncate" style="width:100px">{{ session.cid }}</div>
                    </template>
                    <span class="text-caption text-lg-body-2 font-weight-light">{{ session.cid + ' ' + session.inspectorId }}</span>
                </v-tooltip>
                <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
                    <v-list>
                        <v-list-item v-for="(item, index) in contextMenu" :key="index" @click="rightClickHandler({ action: 'close', cid: session.cid })">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="font-weight-light text-uppercase mr-4">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-tab>
            <v-tabs-items class="fill-height" v-model="tab">
                <v-tab-item transition="false" reverse-transition="false" v-for="session in sessions" v-bind:key="`${session.cid} ${session.inspectorId}`" :value="`${session.cid}`" style="height: 95%">
                    <iframe v-if="!session.stub" height="100%" width="100%" :src="session.src" :title="`DevTools Session ${session.inspectorid}`"></iframe>
                    <div v-else>
                        <v-overlay absolute>
                            <v-card class="mx-auto" max-width="800" outlined>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div class="overline mb-4">NOTE</div>
                                        <v-list-item-title class="headline mb-1">
                                            <span class="font-weight-light text-h5">Debugging session was opened in a browser tab.</span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="font-italic">
                                                Hint: (
                                                <span class="yellow--text darken-2">Ctrl+Click</span>) is used to open a session in a browser tab.
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-card-actions class="d-flex justify-center">
                                    <v-btn outlined color="green lighten-2" width="150px" v-on:click.exact="openHandler(session.item)" :loading="loading[`${session.item.tunnelSocket ? session.item.tunnelSocket.cid : undefined}`]">Open here</v-btn>

                                </v-card-actions>
                            </v-card>
                            <v-alert dismissible
                                     type="error"
                                     transition="scale-transition"
                                     class="mt-8 alert"
                                     :value="alert(session.item.tunnelSocket.cid)">
                                <span class="font-italic">{{ xhrStatus[`${session.item.tunnelSocket ? session.item.tunnelSocket.cid : undefined}`] }}</span>
                            </v-alert>
                        </v-overlay>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <v-snackbar :value="snackbar" bottom color="error" outlined tile class="pb-12">Error</v-snackbar>
    </v-container>
</template>

<style scoped>
.alert {
    position: absolute;
    width: 100%;
}
.gradient {
    background-image: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 0, 0.01),
        rgba(255, 255, 0, 0.01) 5px,
        rgba(0, 0, 0, 0.05) 5px,
        rgba(0, 0, 0, 0.05) 10px
    );
}
</style>
<style>
.v-window__container {
    height: 100%;
}
</style>
<script>
export default {
    name: "DebuggerUI",
    data() {
        return {
            x: 0,
            y: 0,
            showMenu: false,
            tab: undefined,
            contextMenu: [{ title: "close", icon: "fa-times" }],
            snackbar: false,
            loading: {},
            xhrStatus: {}
        };
    },
    methods: {
        show(e) {
            e.preventDefault();
            this.showMenu = false;
            this.x = e.clientX;
            this.y = e.clientY;
            this.$nextTick(() => {
                this.showMenu = true;
            });
        },
        openDebugPanel: function (cid) {
            this.tab = cid;
        },
        rightClickHandler: function (options) {
            let { action, cid } = options;
            switch (action) {
                case "close":
                    this.$debugger.removeSession(cid);
                    break;
            }
        },
        openHandler: function (item) {
            this.$set(this.xhrStatus, item.tunnelSocket.cid, undefined);
            this.$debugger.openRemoteDevToolsURL({ caller: this, item });
        },
        alert: function(cid) {
            return this.xhrStatus[cid] ? true : false;
        }
    },
    watch: {
        $route(to) {
            if (to.query && to.query.cid) this.openDebugPanel(to.query.cid);
        },
    },
    computed: {
        sessions: function () {
            return this.$debugger.sessions();
        }
    },
    mounted() {
        let self = this;
        self.$debugger
            .on("sessionAdded", () => {
                self.$forceUpdate();
            })
            .on("sessionUpdated", () => {
                self.$forceUpdate();
            });
    },
};
</script>